var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div'),_c('div',[_c('v-data-table',{staticClass:"flex d-block mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"single-expand":true,"expand-icon":"fas fa-chevron-down","item-key":"id","loading":_vm.loading,"show-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-4",attrs:{"value":true,"icon":"fas fa-info-circle"}},[_vm._v(" No existen ordenes vinculadas ")])]},proxy:true},{key:"top",fn:function(){return undefined},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY")))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text text-end"},[_vm._v(" "+_vm._s(_vm._f("moneda")(_vm.totalOrder(item)))+" ")])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":_vm.getStatusColor(item)}},[_vm._v(_vm._s(_vm.getStatusName(item)))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text text-center"},[_c('v-icon',{staticClass:"error--text",attrs:{"x-small":""},on:{"click":function($event){return _vm.removeInvoice(item)}}},[_vm._v("fas fa-trash")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4 px-10",attrs:{"colspan":headers.length}},[_c('detailed-purchase-order',{attrs:{"purchaseOrder":item,"inSet":true}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }